<template>
  <!-- <div class="mt-5 mb-5"></div> -->

  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="">
      <div class="mt-5 mb-5"></div>
      <Card>
        <div style="text-align: center;">
          <img alt="Logo" class="mb-4" src="../assets/logo.png" width="100">
          <h1 class="h3 mb-3 fw-bold" style="color: #03253D;">MEMBERS' PORTAL</h1>
        </div>

        <div class="mx-l-5 px-5">
          <Form ref="loginValidate" :model="loginValidate" :rules="loginRuleValidate" class="px-l-5">

            <FormItem prop="username" class="px-l-5">
              <Input type="text" v-model="loginValidate.username" placeholder="Email or Username">
              <template #prepend>
                <Icon type="ios-person-outline"></Icon>
              </template>
              </Input>
            </FormItem>

            <FormItem prop="password">
              <Input type="password" v-model="loginValidate.password" placeholder="Password">
              <template #prepend>
                <Icon type="ios-lock-outline"></Icon>
              </template>
              </Input>
            </FormItem>

            <FormItem class="d-flex justify-content-center">
              <router-link to="/forgot-password">Forgot Password?</router-link>
            </FormItem>

            <FormItem class="d-flex justify-content-center">
              <Button type="primary" @click="handleSubmit('loginValidate')" :loading="loading">
                <span v-if="!loading">LOG IN</span>
                <span v-else>Loading...</span>
              </Button>
            </FormItem>

          </Form>
        </div>
      </Card>
    </div>
  </div>

  <!---->
  <!-- <div class="login"> -->
  <!--   <div class="container py-5"> -->
  <!--     <div class="row"> -->
  <!--       <div class="col-lg-3"></div> -->
  <!--       <div class="col-xl-6 col-lg-6 wow card pt-3 pb-3"> -->
  <!--         <div style="text-align: center;"> -->
  <!--           <img alt="Logo" class="mb-4" src="../assets/logo.png" width="100"> -->
  <!--           <h1 class="h3 mb-3 fw-normal">Please sign in</h1> -->
  <!--         </div> -->
  <!--         <form autocomplete="off" @submit.prevent="login"> -->
  <!--           <div class="mb-3"> -->
  <!--             <label class="form-label" for="code">Business Code -->
  <!--               <span> -->
  <!--                 <Tooltip content="Enter the Business Number. This is given by the business you opened an account with" -->
  <!--                   max-width="300" placement="top"> -->
  <!--                   <i class="fa-solid fa-info-circle"></i> -->
  <!--                 </Tooltip> -->
  <!--               </span> -->
  <!--             </label> -->
  <!--             <input id="code" v-model="code" autofocus class="form-control border-1 bg-light px-4" -->
  <!--               placeholder="Enter Business Code" required type="text"> -->
  <!--           </div> -->
  <!---->
  <!--           <div class="mb-3"> -->
  <!--             <label class="form-label" for="username">Username -->
  <!--               <span> -->
  <!--                 <Tooltip content="Enter your Username. This can be your Email ID or Phone number" max-width="200" -->
  <!--                   placement="top"> -->
  <!--                   <i class="fa-solid fa-info-circle"></i> -->
  <!--                 </Tooltip> -->
  <!--               </span> -->
  <!--             </label> -->
  <!--             <input v-model="username" id="username" class="form-control border-1 bg-light px-4" -->
  <!--               placeholder="Enter your Username" required type="text"> -->
  <!--           </div> -->
  <!---->
  <!--           <div class="mb-3"> -->
  <!--             <label class="form-label" for="password">Password -->
  <!--               <span> -->
  <!--                 <Tooltip content="Please a strong, hard to guess password and keep it safe" max-width="200" -->
  <!--                   placement="top"> -->
  <!--                   <i class="fa-solid fa-info-circle"></i> -->
  <!--                 </Tooltip> -->
  <!--               </span> -->
  <!--             </label> -->
  <!--             <input v-model="password" class="form-control border-1 bg-light px-4" id="password" -->
  <!--               placeholder="Enter your password" required type="password"> -->
  <!--           </div> -->
  <!---->
  <!---->
  <!--           <div class="row g-3"> -->
  <!---->
  <!--             <div class="col-12 d-none"> -->
  <!--               <a class="btn btn-link"> -->
  <!--                 Forgot Your Password? -->
  <!--               </a> -->
  <!--             </div> -->
  <!--             <div class="col-12"> -->
  <!--               <button class="btn btn-primary w-100" type="submit"> -->
  <!--                 Login Now -->
  <!--                 <span v-if="loading" class="spinner-border spinner-border-sm" role="status"> -->
  <!--                   <span class="visually-hidden">Loading...</span> -->
  <!--                 </span> -->
  <!--               </button> -->
  <!--             </div> -->
  <!--           </div> -->
  <!--         </form> -->
  <!--       </div> -->
  <!--     </div> -->
  <!--   </div> -->
  <!-- </div> -->

</template>

<script>
export default {
  data() {
    return {
      // code: '',
      username: '',
      password: '',
      loading: false,

      loginValidate: {
        username: '',
        password: '',
      },

      loginRuleValidate: {
        username: [
          { required: true, message: 'username is required', trigger: 'change', type: 'string' }
        ],
        password: [
          { required: true, message: 'password is required', trigger: 'change', type: 'string' }
        ],
      },

    }
  },

  methods: {
    handleSubmit(name) {
      this.loading = true

      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$Loading.start()

          let formData = new FormData();

          formData.append('username', this.loginValidate.username);
          formData.append('password', this.loginValidate.password);

          this.$store
            .dispatch('clientLogin', formData)
            .then((response) => {
              if (response?.data?.businesses?.length === 1) {
                // return this.$router.push({ name: "home", replace: true });

                this.loading = false

                // this.success('Login Success', '')

                this.$Loading.finish();
                // console.log(response)

                return this.$router.push('/')

              } else {

                return this.$router.push({ path: '/select-business' });
              }
            })
            .catch(err => {
              //console.log(err)
              this.$Loading.error()

              this.loading = false

              this.$Message.error(`Error: ${err?.response?.data?.response ?? err?.response?.data?.message ?? err.message}`);
            })
        } else {
          this.loading = false

          this.$Message.error('Some required fields are missing!');
        }
      })

    },
    login() {
      this.loading = true
      let formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);
      formData.append('bizz_no', this.code);

      this.$store
        .dispatch('login', formData)
        .then(() => {
          this.loading = false
          this.success('Login Success', '')
          this.$router.push({ name: 'home' })
        })
        .catch(err => {
          //console.log(err)
          this.loading = false
          if (err.code == 'ERR_BAD_REQUEST') {
            this.error('Login Failed', err.response.data.response)
          }
          else if (err.code == 'ERR_NETWORK') {
            this.error(err.code, err.message)
          } else {
            this.error(err.code, '')
          }

        })
    }
  }
}
</script>

<style scoped></style>
