<template>
  <div class="bg-light">
    <nav-bar></nav-bar>
    <router-view></router-view>
    <custom-footer></custom-footer>
  </div>
</template>
<script>
import axios from "axios";
import NavBar from "@/components/NavBar";
import CustomFooter from "@/components/Footer";

export default {
  name: 'App',
  components: { NavBar, CustomFooter },
  data() {
    return {}
  },
  created() {
    const userToken = localStorage.getItem('token')

    if (userToken) {
      axios.defaults.headers.common["Authorization"] = `Token ${userToken}`;
      this.$store.dispatch('getUser')
    } else {
      localStorage.removeItem('token')
    }

    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('LOGOUT')
        }
        return Promise.reject(error)
      }
    )

    this.$Message.config({
      top: 50,
      duration: 3
    });
  }
}
</script>

<style scoped>
#app {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F8F9FA;
  background: #F8F9FA;
}
</style>
