<template>
  <div class="container py-3">
    <div v-if="!getLoanTopUps">
      <loading-view />
    </div>

    <div class="table-responsive bg-white" v-else>

      <!-- large devices -->
      <nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-flex justify-content-start">
        <div class="w-50">
          <h1 class="navbar-brand" href="#">&nbsp;LOAN TOP UP REQUESTS</h1>
        </div>
      </nav>

      <!-- small devices  -->
      <nav class="navbar navbar-expand-lg navbar-light bg-light d-lg-none">
        <div class="w-100 text-center">
          <h1 class="navbar-brand">LOAN TOP UP REQUESTS</h1>
        </div>
      </nav>

      <!-- large devices -->
      <table v-if="getLoanTopUps?.results?.length > 0" class="table table-hover d-none d-lg-table ">

        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Created</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Loan</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(loanTopUpRequest, i) in this.getLoanTopUps.results" :key="i">
            <td>{{ this.loanTopUpRequestPage === 1 ? i + 1 : ((this.loanTopUpRequestPage - 1) * 30) + i + 1
              }}. </td>
            <td>{{ new Date(loanTopUpRequest?.date).toDateString() ?? "N/A" }} </td>
            <td>{{ new Intl.NumberFormat('en-US', {
            }).format(loanTopUpRequest?.amount) }}</td>
            <td>{{ loanTopUpRequest?.status ?? "N/A" }} </td>
            <td>
              <Link :to="`/loan-details/${loanTopUpRequest?.loan?.id}`">
              Details
              </Link>
            </td>
            <!-- {{ loanTopUpRequest }} -->
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="6">
              <Space type="flex">
                <Page :total="getLoanTopUps.total_count" :page-size="30" v-model="loanTopUpRequestPage" show-total
                  @on-change="handleChangeLoanTopUpRequestPage" />
              </Space>
            </td>
          </tr>
        </tfoot>

      </table>

      <table v-else class="table table-hover d-none d-lg-table ">

        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Created</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Loan</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="6" class="text-center">
              No Data Available
            </td>
          </tr>
        </tbody>
      </table>


      <!-- smaller devices -->
      <table v-if="getLoanTopUps?.results?.length > 0" class="table table-hover d-lg-none ">

        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Created</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Loan</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(loanTopUpRequest, i) in this.getLoanTopUps.results" :key="i">
            <td>{{ this.loanTopUpRequestPage === 1 ? i + 1 : ((this.loanTopUpRequestPage - 1) * 30) + i + 1
              }}. </td>
            <td>{{ new Date(loanTopUpRequest?.date).toDateString() ?? "N/A" }} </td>
            <td>{{ new Intl.NumberFormat('en-US', {
            }).format(loanTopUpRequest?.amount) ?? "N/A" }}
            </td>
            <td>{{ loanTopUpRequest?.status ?? "N/A" }} </td>
            <td>
              <!-- <Link :to="/loan-detail?loan=loanTopUpRequest?.loan?.id"> -->
              <!-- Details -->
              <!-- </Link> -->
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="5">
              <Space type="flex">
                <Page :total="getLoanTopUps.total_count" :page-size="30" v-model="loanTopUpRequestPage" show-total
                  @on-change="handleChangeLoanTopUpRequestPage" />
              </Space>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

</template>

<script>
import LoadingView from "../../components/Loading.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoanTopUpRequestsView",
  data() {
    return {
      queryParams: {},
      modal: false,
      openLoanTopUpModal: false,
      openDeleteModal: false,
      loanTopUpRequestPage: 1,
    }
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    ...mapActions({
      fetchAccounts: 'fetchAccounts',
      fetchLoanTopUps: 'fetchLoanTopUps',
    }),
    handleChangeLoanTopUpRequestPage(value) {

      this.$store
        .dispatch('fetchLoanTopUps', { page: value })
    },
  },
  computed: {
    ...mapGetters([
      'getLoanTopUps',
    ])
  },
  created() {
    this.$watch(
      () => this.$route.query,
      (newURLSearchParams /*, oldURLSearchParams */) => {
        // react to route changes...
        this.queryParams = newURLSearchParams;

        // console.log(newURLSearchParams, oldURLSearchParams)

        this.fetchAccounts()
          .then(() => {
            this.$store
              .dispatch('fetchLoanTopUps', { page: this.loanTopUpRequestPage })
              .then()
              .catch(() => {
                this.$Message.error("An error occured while fetching loan top up requests")
              })
          })
          .catch((err) => {
            this.$Message.error(`${err.message}`)
          })

      }
    )

  },
  mounted() {
    // console.log(this.$route.query)
    this.queryParams = this.$route.query

    this.fetchAccounts()
      .then(() => {
        this.$store
          .dispatch('fetchLoanTopUps', { page: this.loanTopUpRequestPage })
          .then()
          .catch(() => {
            this.$Message.error("An error occured while fetching loan top up requests")
          })
      })
      .catch((err) => {
        this.$Message.error(`${err.message}`)
      })

  },
  components: {
    LoadingView,
  }

}
</script>

<style scoped></style>
