<template>
  <div class="container py-3">
    <div v-if="!getLoanRequests">
      <loading-view />
    </div>

    <div class="table-responsive bg-white" v-else>

      <!-- large devices -->
      <nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-flex justify-content-center">
        <div class="w-50">
          <h1 class="navbar-brand" href="#">&nbsp;LOAN REQUESTS</h1>
        </div>

        <div class="w-50 d-flex justify-content-end collapse navbar-collapse" id="navbarSupportedContent">

          <Space wrap>
            <Dropdown>
              <Button type="secondary" size="default">
                Loan Application
                <Icon type="ios-arrow-down"></Icon>
              </Button>
              <template #list>
                <DropdownMenu>
                  <DropdownItem @click="isModalOpen = true">Upload</DropdownItem>
                  <DropdownItem @click="downloadTemplate">Download Template</DropdownItem>
                </DropdownMenu>
              </template>
            </Dropdown>
            <Button type="secondary" @click="openLoanModal = true">
              NEW LOAN REQUEST
            </Button>
          </Space>
        </div>
      </nav>

      <!-- small devices  -->
      <nav class="navbar navbar-expand-lg navbar-light bg-light d-lg-none">
        <div class="w-100 text-center">
          <h1 class="navbar-brand">LOAN REQUESTS</h1>
        </div>

        <div class="w-100 d-flex justify-content-center align-items-center my-1">

          <Button type="default" size="default" @click="openLoanModal = true">
            NEW REQUEST
          </Button>
        </div>

        <div class="w-100 d-flex justify-content-center align-items-center my-1">
          <div class="fw-light"><span class="text-danger">*</span> A - APPROVED, P - PENDING</div>
        </div>

      </nav>

      <!-- large devices -->
      <table class="table table-hover d-none d-lg-table " v-if="getLoanRequests?.results?.length > 0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Created</th>
            <th scope="col">Amount</th>
            <th scope="col">Duration</th>
            <th scope="col">Loan Type</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(loanRequest, i) in this.getLoanRequests?.results" :key="i">
            <td>{{ this.loanRequestPage === 1 ? i + 1 : ((this.loanRequestPage - 1) * 30) + i + 1 }}. </td>
            <td>{{ new Date(loanRequest?.last_modified).toDateString() ?? "N/A" }} </td>
            <td>{{ new Intl.NumberFormat('en-US', {
            }).format(loanRequest?.amount) ?? "N/A" }}</td>
            <td>{{ loanRequest?.duration_number && loanRequest?.duration_frequency ?
              `${loanRequest?.duration_number} ${loanRequest?.duration_frequency}` : "N/A" }} </td>
            <td>{{ loanRequest?.loan_type?.name ?? "N/A" }} </td>
            <td>
              {{ loanRequest?.is_approved }}
            </td>
            <td>
              <Space wrap>
                <Button :to="{ name: 'loan-request-detail', params: { id: loanRequest.id } }">
                  View
                </Button>

                <Button v-if="loanRequest?.is_approved === 'PENDING'" type="error"
                  @click="this.selectLoanRequest(loanRequest)">
                  Delete
                </Button>

              </Space>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="7">
              <Space type="flex">
                <Page :total="getLoanRequests?.total_count" :page-size="30" v-model="loanRequestPage" show-total
                  @on-change="handleChangeLoanRequestPage" />
              </Space>
            </td>
          </tr>
        </tfoot>

      </table>

      <table class="table table-hover d-none d-lg-table" v-else>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Created</th>
            <th scope="col">Amount</th>
            <th scope="col">Duration</th>
            <th scope="col">Loan Type</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="7" class="text-center">
              No Data Available
            </td>
          </tr>
        </tbody>
      </table>



      <!-- smaller devices -->
      <table class="table table-hover d-lg-none " v-if="getLoanRequests?.results?.length > 0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Amount</th>
            <th scope="col">Duration</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(loanRequest, i) in this.getLoanRequests?.results" :key="i">
            <td>{{ this.loanRequestPage === 1 ? i + 1 : ((this.loanRequestPage - 1) * 30) + i + 1 }}. </td>
            <td><strong>{{ new Intl.NumberFormat('en-US', {
            }).format(loanRequest?.amount) ?? "N/A" }}</strong></td>
            <td>{{ loanRequest?.duration_number && loanRequest?.duration_frequency ?
              `${loanRequest?.duration_number} ${loanRequest?.duration_frequency}` : "N/A"
              }} </td>
            <td class="text-center">
              {{ loanRequest?.is_approved?.split("")[0] }}
            </td>
            <td>
              <Space>
                <Button size="small" :to="{ name: 'loan-request-detail', params: { id: loanRequest.id } }">
                  <Icon type="ios-eye-outline" />
                  <!-- View -->
                </Button>

                <Button size="small" v-if="loanRequest?.is_approved === 'PENDING'" type="error"
                  @click="this.selectLoanRequest(loanRequest)">
                  <Icon type="ios-trash-outline" />
                  <!-- Delete -->
                </Button>

              </Space>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="5">
              <Space type="flex">
                <Page :total="getLoanRequests?.total_count" :page-size="30" v-model="loanRequestPage" show-total
                  @on-change="handleChangeLoanRequestPage" />
              </Space>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <Modal v-model="openLoanModal" title="Request New Loan" ok-text="Submit" cancel-text="Cancel"
    @on-cancel="handleResetLoanRequest('loanFormValidate')" :loading="loadingStatus"
    @on-ok="handleSubmitLoanRequest('loanFormValidate')">
    <Form ref="loanFormValidate" :model="loanFormValidate" label-position="left" :label-width="100"
      :rules="loanRuleValidate">
      <FormItem label="Date" prop="date">
        <DatePicker type="date" placeholder="Select date" v-model="loanFormValidate.date" :options="datePickerOptions">
        </DatePicker>
      </FormItem>

      <FormItem label="Amount" prop="amount">
        <InputNumber :min="0" v-model="loanFormValidate.amount"
          :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          :parser="value => value.replace(/\$\s?|(,*)/g, '')" class="w-100" />
      </FormItem>

      <FormItem label="Loan Type" prop="loanType">
        <Select v-model="loanFormValidate.loanType" filterable placeholder="Select Loan Type">
          <Option v-for="(loanType, idx) in this.getBusinessLoanTypes" :value="loanType.id?.toString()" :key="idx">{{
            loanType.name + ` - ${loanType.interest_rate}% Interest ${loanType.formula === 4 ? 'Per Day' :
              loanType.formula === 3 ? 'Per Week' :
                loanType.formula === 2 ? 'Per Month' :
                  'Per Annum'
            }` }}</Option>
        </Select>
      </FormItem>

      <FormItem label="Duration">
        <Row>
          <Col span="11">
          <FormItem prop="durationNumber">
            <InputNumber :min="0" v-model="loanFormValidate.durationNumber" class="w-100" />
          </FormItem>
          </Col>

          <Col span="2">
          </Col>

          <Col span="11">
          <FormItem prop="durationFrequency">
            <Select v-model="loanFormValidate.durationFrequency" placeholder="Select Duration">

              <Option
                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt?.id === parseInt(loanFormValidate?.loanType))[0]['interval'] === 0"
                value="days">DAYS</Option>
              <Option
                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt?.id === parseInt(loanFormValidate?.loanType))[0]['interval'] === 1"
                value="weeks">WEEKS</Option>
              <Option
                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt?.id === parseInt(loanFormValidate?.loanType))[0]['interval'] === 2"
                value="fortnights">FORTNIGHTS</Option>
              <Option
                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt?.id === parseInt(loanFormValidate?.loanType))[0]['interval'] === 3"
                value="months">MONTHS</Option>
              <Option
                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt?.id === parseInt(loanFormValidate?.loanType))[0]['interval'] === 4"
                value="years">YEARS</Option>

            </Select>
          </FormItem>
          </Col>
        </Row>
      </FormItem>

      <div v-if="this.$store?.state?.user?.guarantors_required_on_loan_request === true">
        <Divider orientation="left">Guarantors</Divider>

        <FormItem label="Guarantors" prop="guarantorList">
          <Select v-model="loanFormValidate.guarantorList" filterable clearable multiple
            :remote-method="searchForGuarantor" :loading="guarantorSearchLoading" placeholder="Select Guarantor">
            <Option v-for="(option, index) in guarantorListOptions" :value="option.value" :key="index"
              :disabled="option.value === this.$store?.state?.user?.biodata?.id">
              {{ option.label }}
            </Option>
          </Select>
        </FormItem>
      </div>

      <div v-if="this.$store?.state?.user?.security_required_on_loan_request === true">
        <Divider orientation="left">
          Security
        </Divider>

        <div>
          <FormItem label="File" prop="securityFile">
            <Upload :before-upload="handleUpload" prop="securityFile" action="/" max-size="5120"
              accept="image/*, .pdf, application/*">
              <Button icon="ios-cloud-upload-outline">Select the file to upload (max 5mb)</Button>
            </Upload>
            <div v-if="loanFormValidate.securityFile !== null"> {{ loanFormValidate.securityFile.name }}
            </div>
          </FormItem>
        </div>

        <div>
          <FormItem label="Valuation" prop="securityValue">
            <InputNumber :min="0" v-model="loanFormValidate.securityValue"
              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\$\s?|(,*)/g, '')" class="w-100" />
          </FormItem>
        </div>

        <div>
          <FormItem label="Name" prop="securityName">
            <Input v-model="loanFormValidate.securityName" placeholder="Type security name..."></Input>
          </FormItem>
        </div>

        <div>
          <FormItem label="Description" prop="securityDescription">
            <Input v-model="loanFormValidate.securityDescription" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
              placeholder="Type security description..."></Input>
          </FormItem>
        </div>
      </div>


      <!-- <FormItem> -->
      <!--     <Row> -->
      <!--         <Col span="5"> -->
      <!--         <Button @click="handleResetLoanRequest('loanFormValidate')" v-if="!loadingStatus"> -->
      <!--             Reset -->
      <!--         </Button> -->
      <!--         </Col> -->
      <!---->
      <!--         <Col span="1"> -->
      <!--         </Col> -->
      <!---->
      <!--         <Col span="4"> -->
      <!--         <Button type="primary" @click="handleSubmitLoanRequest('loanFormValidate')" -->
      <!--             :loading="loadingStatus"> -->
      <!--             <span v-if="!loadingStatus">Submit</span> -->
      <!--             <span v-else>Loading...</span> -->
      <!--         </Button> -->
      <!--         </Col> -->
      <!--         <Col span="8"> -->
      <!--         </Col> -->
      <!--     </Row> -->
      <!---->
      <!-- </FormItem> -->
    </Form>
  </Modal>


  <Modal v-model="openDeleteModal" title="Delete Action" footer-hide=true>
    <Result type="error" title="Are your sure you want to perform this action?">
      <template #desc>
        Delete Loan Request
      </template>
      <template #extra>
        <!-- <div> suree</div> -->
        <div>
          <Icon type="ios-close-circle-outline" color="#ed4014" />
          Clicking the "Continue" button will delete this record.
          <!-- <a class="ivu-ml-16"> -->
          <!--     This will remove xyz. <Icon type="ios-arrow-forward" /> -->
          <!-- </a> -->
        </div>
      </template>

      <template #actions>
        <Button type="error" @click="deleteSelectedLoanRequest(this.selectedLoanRequest)">Continue</Button>
      </template>
    </Result>
  </Modal>

  <!-- Modal -->
  <div v-if="isModalOpen" class="modal d-block" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Upload Loan Application</h5>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <div class="mb-3">
              <label class="form-label">Select PDF File (Max 5MB)</label>
              <input type="file" @change="handleFileChange" accept="application/pdf" class="form-control"
                ref="fileInput">
              <div v-if="error" class="form-text text-danger mt-2">
                {{ error }}
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button type="default" @click="closeModal">
            Cancel
          </Button>
          <Button icon="ios-cloud-upload-outline" type="primary" @click="handleSubmit" :disabled="!selectedFile">
            Upload
          </Button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal backdrop -->
  <div v-if="isModalOpen" class="modal-backdrop show"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingView from "../../components/Loading.vue"

export default {
  name: "LoanRequestsView",
  data() {
    return {
      queryParams: {},
      modal: false,
      openLoanModal: false,
      openWithdrawModal: false,
      openDeleteModal: false,
      openUploadModal: false,

      loanFormValidate: {
        date: '',
        amount: '',
        durationFrequency: '',
        durationNumber: null,
        loanType: '',
        // securities: [{ securityFile: null, value: 0, name: "", description: "" }],
        guarantorList: [],
        securityFile: null,
        securityName: "",
        securityValue: "",
        securityDescription: "",
      },
      loanRuleValidate: {
        date: [
          { required: true, type: 'date', message: 'Please select the date', trigger: 'change' }
        ],
        amount: [
          { required: true, message: 'Amount must be more than 0', trigger: 'change', type: 'number' }
        ],
        durationNumber: [
          { required: true, message: 'Field must be more than 0', trigger: 'change', type: 'number', min: 0 }
        ],
        durationFrequency: [
          { required: true, type: 'string', message: 'Select a valid option', trigger: 'change' }
        ],
        loanType: [
          { required: true, message: 'Select valid loan type', trigger: 'change' },
        ],
        // securities: [{ required: true, type: 'array', min: 1, message: 'Choose at least one security', trigger: 'change' },],
        guarantorList: [{ required: true, type: 'array', min: 1, message: 'Choose at least one guarantor', trigger: 'change' },
        ],
        securityFile: [
          { required: true, message: 'Select a file', trigger: 'change', type: 'object' }
        ],
        securityValue: [
          { required: true, type: 'number', message: 'Input a valid amount', trigger: 'change' }
        ],
        securityName: [
          { required: true, type: 'string', message: 'Add a name', trigger: 'change' }
        ],
        securityDescription: [
          { required: true, type: 'string', message: 'Add a description!', trigger: 'blur' }
        ],

      },

      loanUploadFormValidate: {
      },
      loanUploadRuleValidate: {

      },
      loadingStatus: false,
      // securities: [{ securityFile: null, value: 0, name: "", description: "" }],
      // guarantorList: [],
      guarantorSearchLoading: false,
      selectedLoanRequest: {},
      loanRequestPage: 1,
      datePickerOptions: {
        disabledDate(date) {
          const disabledDay = date.getDate();

          return disabledDay !== (new Date()).getDate();
        }
      },
      isModalOpen: false,
      selectedFile: null,
      error: null
    }
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    ...mapActions({
      fetchLoanRequests: 'fetchLoanRequests',
      fetchWithdrawRequests: 'fetchWithdrawRequests',
      fetchAccounts: 'fetchAccounts',
      fetchBusinessLoanTypes: 'fetchBusinessLoanTypes',
      fetchLoanRequestGuarantor: 'fetchLoanRequestGuarantor',
    }),
    handleSubmitLoanRequest(name) {


      if (this.$store?.state?.user?.guarantors_required_on_loan_request || this.$store?.state?.user?.security_required_on_loan_request) {

        this.$refs[name].validate((valid) => {
          if (valid) {

            this.loadingStatus = true;

            this.$Loading.start()

            const formData = new FormData()

            // append loan details
            for (const [key, value] of Object.entries(this.loanFormValidate)) {

              if (key === "securityName") {

                formData.append(`security_0_name`, value);
              }

              else if (key === "securityValue") {

                formData.append(`security_0_valuation`, value);
              }

              else if (key === "securityFile") {

                formData.append(`security_0_file`, value);
              }

              else if (key === "securityDescription") {

                formData.append(`security_0_description`, value);
              }

              else if (key === "guarantorList") {

                formData.append("guarantors", JSON.stringify(value));
              }

              else if (key === "date") {

                formData.append("date", (new Date(value)).toISOString());
              } else {

                formData.append(`${key}`, value)
              }
            }

            // append guarantors
            // formData.append("guarantors", JSON.stringify(this.loanFormValidate.guarantorList))

            // append securities
            // for (let i = 0; i < this.securities.length; i++) {
            //     formData.append(`security_${i}_file`, this.loanFormValidate.securities[i].securityFile)
            //     formData.append(`security_${i}_valuation`, this.loanFormValidate.securities[i].value)
            //     formData.append(`security_${i}_name`, this.loanFormValidate.securities[i].name)
            //     formData.append(`security_${i}_description`, this.loanFormValidate.securities[i].description)
            // }

            // Display the key/value pairs
            // for (const pair of formData.entries()) {
            //     console.log(pair[0], pair[1]);
            // }
            this.$store
              .dispatch('requestNewLoan', formData, "multipart/form-data")
              .then(() => {
                this.$Message.success('Successfully requested for loan with guarantors & security');

                setTimeout(() => {

                  this.$Loading.finish()

                  this.loadingStatus = false;

                  this.$refs[name].resetFields();

                  this.openLoanModal = false;

                  // this.$store
                  //     .dispatch('fetchLoanRequests', {page: this.loanRequestPage })
                  this.$router.go(0)

                }, 1000);
              })
              .catch(err => {

                this.$Loading.finish()

                this.loadingStatus = false;

                this.$Message.error(`Error: ${err?.response?.data?.message ?? err.message}`);
              })

          } else {
            this.$Message.error('Some required fields are missing!');
          }
        })

      } else {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.$Loading.start()

            this.loadingStatus = true;

            // data to be encoded as application/json
            this.$store
              .dispatch('requestNewLoan', this.loanFormValidate, "application/json")
              .then(() => {
                this.$Message.success('Successfully requested for loan');

                setTimeout(() => {
                  this.$Loading.finish()

                  this.loadingStatus = false;

                  this.$refs[name].resetFields();

                  this.openLoanModal = false;

                  // this.$store
                  //     .dispatch('fetchLoanRequests', { page: this.loanRequestPage })
                  this.$router.go(0)
                }, 1000);
              })
              .catch(err => {
                this.$Loading.error()

                this.$Message.error(`Error: ${err?.response?.data?.message ?? err.message}`);

                this.loadingStatus = false;
              })
          } else {
            this.$Message.error('Some fields are missing!');
          }
        })
      }
    },
    handleResetLoanRequest(name) {
      this.$refs[name].resetFields();
    },
    handleSubmitLoanUpload(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$Loading.start()

          this.loadingStatus = true;
          this.loadingStatus = false;

          this.$Message.success('Successfully uploaded loan application');
          // data to be encoded as application/json
          // this.$store
          //   .dispatch('requestNewLoan', this.loanFormValidate, "application/json")
          //   .then(() => {
          //     this.$Message.success('Successfully requested for loan');
          //
          //     setTimeout(() => {
          //       this.$Loading.finish()
          //
          //       this.loadingStatus = false;
          //
          //       this.$refs[name].resetFields();
          //
          //       this.openLoanModal = false;
          //
          //       // this.$store
          //       //     .dispatch('fetchLoanRequests', { page: this.loanRequestPage })
          //       this.$router.go(0)
          //     }, 1000);
          //   })
          //   .catch(err => {
          //     this.$Loading.error()
          //
          //     this.$Message.error(`Error: ${err?.response?.data?.message ?? err.message}`);
          //
          //     this.loadingStatus = false;
          //   })
        } else {
          this.$Message.error('Some fields are missing!');
        }
      })
    },
    handleResetLoanUpload(name) {
      this.$refs[name].resetFields();
    },
    searchForGuarantor(query) {
      if (query !== '') {
        this.guarantorSearchLoading = true;

        setTimeout(() => {
          this.$store
            .dispatch('fetchLoanRequestGuarantor', query)
            .then((response) => {
              this.guarantorSearchLoading = false;

              this.guarantorListOptions = response?.data?.data?.map((item, idx) => ({
                label: `${item?.members?.biodata?.name ?? "N/A"}, ${item?.members?.biodata?.contact ?? "N/A"}`,
                value: item?.members?.id ?? idx,
                ...item
              })) ?? [];
            })
            .catch(() => {
              this.guarantorListOptions = [];
            })

        }, 500);

      } else {
        this.guarantorListOptions = [];
      }
    },
    addSecurity() {
      this.securities.push({ file: null, value: 0 })
    },
    removeSecurity(index) {
      this.securities.splice(index, 1);
    },
    handleUpload(file) {
      // console.log("we here")
      this.loanFormValidate.securityFile = file;
      return false;
    },
    uploadSecurityFile($event, index) {

      this.securities = this.securities.map((security, idx) => {
        if (index === idx) {
          // const currentRef = this.$refs[`security_${index}`]

          return ({ ...security, securityFile: $event.target.files[0] });
        } else {
          return security;
        }
      });
    },
    selectLoanRequest(loanRequest) {
      // console.log(loanRequest)
      this.selectedLoanRequest = loanRequest;
      this.openDeleteModal = true;
    },
    deleteSelectedLoanRequest(selectedLoanRequest) {
      this.$store
        .dispatch('deleteLoanRequest', selectedLoanRequest.id)
        .then(() => {
          this.$Message.success('Successfully deleted loan request');

          setTimeout(() => {
            this.$store
              .dispatch('fetchLoanRequests', { page: this.loanRequestPage })

            this.openDeleteModal = false;
          }, 1000);
        })
        .catch((err) => {
          this.$Message.error(`${err}`);
        })
    },
    handleChangeLoanRequestPage(value) {

      this.$store
        .dispatch('fetchLoanRequests', { page: value })
    },
    handleChangeWithdrawRequestPage(value) {

      this.$store
        .dispatch('fetchWithdrawRequests', { page: value })
    }, handleFileChange(event) {
      const file = event.target.files[0]
      this.error = null

      if (!file) {
        this.selectedFile = null
        return
      }

      // Check if file is PDF
      if (file.type !== 'application/pdf') {
        this.error = 'Please select a PDF file'
        this.selectedFile = null
        this.$refs.fileInput.value = ''
        return
      }

      // Check file size (5MB = 5 * 1024 * 1024 bytes)
      if (file.size > 5 * 1024 * 1024) {
        this.error = 'File size must not exceed 5MB'
        this.selectedFile = null
        this.$refs.fileInput.value = ''
        return
      }

      this.selectedFile = file
    },
    handleSubmit() {
      if (!this.selectedFile) {
        this.$Message.error("File is Missing");
        return
      }

      const formData = new FormData()

      formData.append('file', this.selectedFile)
      formData.append('business', this.userIn?.business?.id)
      formData.append('applicant', this.userIn?.member_detail?.id)
      formData.append('branch', this.userIn?.member_detail?.branch)
      formData.append('added_by', "CLIENT")

      // console.log(this.userIn)

      // debugger

      this.$store
        .dispatch('uploadLoanApplication', formData)
        .then(() => {
          this.$Message.success('Successfully uploaded loan application');

          setTimeout(() => {
            this.$Loading.finish()

            this.closeModal()

            this.$router.go(0)
          }, 1000);
        })
        .catch(() => {
          this.$Loading.error()

          // this.$Message.error("An error occured while trying to upload loan application");

          this.error = "An error occured while trying to upload loan application";

          this.loadingStatus = false;
        })

    },
    closeModal() {
      this.isModalOpen = false
      this.selectedFile = null
      this.error = null
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = ''
      }
    },
    downloadTemplate() {
      this.$Loading.start()

      this.$store
        .dispatch(
          "downloadLoanApplicationTemplate",
          { business_id: this.userIn?.business?.id }
        )
        .then((response) => {
          // Create a blob URL and trigger download
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;

          // Get filename from Content-Disposition header if available
          const contentDisposition = response.headers['content-disposition'];
          const filename = contentDisposition
            ? contentDisposition.split('filename=')[1]
            : 'template.pdf';

          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.$Loading.finish()
        })
        .catch((error) => {
          this.$Message.error(`Error: ${error.response.data.message ?? error.response.data.error ?? "An error occured while downloading the template."}`)

          this.$Loading.error()
        })
    }
  },
  computed: {
    ...mapGetters([
      'getLoanRequests',
      'getWithdrawRequests',
      'allAccounts',
      'userIn',
      'getBusinessLoanTypes',

    ])
  },
  created() {
    this.$watch(
      () => this.$route.query,
      (newURLSearchParams /*, oldURLSearchParams */) => {
        // react to route changes...
        this.queryParams = newURLSearchParams;

        // console.log(newURLSearchParams, oldURLSearchParams)

        this.fetchAccounts().then(() => {

          this.$store
            .dispatch('fetchWithdrawRequests', { page: this.withdrawRequestPage })

          this.fetchBusinessLoanTypes()

        })
          .catch(err => {
            if (err.code == 'ERR_NETWORK') {
              // this.error(err.code, err.message)
              this.$Message.error(`${err.message}`);
            } else {
              // this.error(err.code, '')
              this.$Message.error(`${err.code}`);
            }
          })
      }
    )

  },
  mounted() {
    // console.log(this.$route.query)
    this.queryParams = this.$route.query

    this.fetchAccounts()
      .then(() => {
        this.$store
          .dispatch('fetchLoanRequests', { page: this.loanRequestPage })


        this.fetchBusinessLoanTypes()


      })
      .catch(err => {
        if (err.code == 'ERR_NETWORK') {
          // this.error(err.code, err.message)
          this.$Message.error(`${err.message}`);
        } else {
          // this.error(err.code, '')
          this.$Message.error(`${err.code}`);
        }
      })

    // Add body class when modal is opened
    this.$watch('isModalOpen', (newVal) => {
      if (newVal) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
    })

  },
  beforeUnmount() {
    // Clean up body class
    document.body.classList.remove('modal-open')
  },
  components: {
    LoadingView,
  }
}
</script>

<style scoped></style>
