import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from "vue";
import ViewUIPlus from "view-ui-plus";
import locale from "view-ui-plus/dist/locale/en-US";
import App from "./App.vue";
import router from "./router";
import common from "./mixims";
import store from "./store";
import "view-ui-plus/dist/styles/viewuiplus.css";

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://app.mfuko.net/api/v1"; //Production
} else {
  axios.defaults.baseURL = "http://localhost:8000/api/v1"; //development
}

router.beforeEach((to, from, next) => {
  ViewUIPlus.LoadingBar.start();
  next();
});

router.afterEach((/* route */) => {
  ViewUIPlus.LoadingBar.finish();
});

createApp(App)
  .use(store)
  .use(router)
  .mixin(common)
  .use(ViewUIPlus, { locale })
  .mount("#app");

import "bootstrap/dist/js/bootstrap.js";
import axios from "axios";

// this interceptor is for when browsers still have a token in localstorage but it is invalid
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      (error.response.status === 401 && !originalRequest._retry) ||
      (error.response.status === 403 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;

      localStorage.clear();

      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);
