<template>
  <div class="home container">

    <!-- large screens  -->
    <div class="d-none d-lg-block d-xl-block">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="w-50">
          <h1 class="navbar-brand" href="#">&nbsp;DASHBOARD</h1>
        </div>

        <div class="w-50 d-flex justify-content-end collapse navbar-collapse" id="navbarSupportedContent">
          <Text type="secondary">Hi {{ userIn?.biodata?.name ?? "Sacco Member" }}</Text>
        </div>
      </nav>

      <Card>
        <div class="container">
          <h4 class="">ACCOUNTS</h4>
        </div>

        <div v-if="this.getDashboard?.accounts" class="container mt-4">
          <div class="row g-4">
            <div v-for="(account, idx) in this?.getDashboard?.accounts" :key="idx" class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Account #{{ account?.account_number ?? "N/A" }}
                  </h5>
                  <p class="card-text">Balance: UGX {{ account?.balance.toLocaleString() ?? "N/A" }}</p>
                  <Button to="/accounts" shape="circle">
                    View Details
                    <Icon type="ios-arrow-forward"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="container mt-4">
          <div class="row g-4">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">No Accounts Available </h5>
                  <p class="card-text">Create an account.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container mt-4">
          <h4 class="">SUMMARY</h4>
        </div>

        <div class="container mt-4">
          <div class="row g-4">

            <div v-if="this?.getDashboard?.shares" class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Shares
                  </h5>
                  <p class="card-text">Total Shares: {{ shareTrans.total_shares ?? "N/A" }}</p>
                  <p class="card-text">Total Share Value: UGX {{ (shareTrans.total_shares *
                    shareTrans.share_price).toLocaleString() ?? "N/A" }}</p>
                  <Button to="/shares" shape="circle">
                    View Details
                    <Icon type="ios-arrow-forward"></Icon>
                  </Button>
                </div>
              </div>
            </div>

            <div v-else class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Shares
                  </h5>
                  <p class="card-text">No Shares Data Available</p>
                </div>
              </div>
            </div>

            <div v-if="this?.getDashboard?.loans" class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Loans
                  </h5>
                  <p class="card-text">Approved Loans: {{ this?.getDashboard?.loans?.number_approved ?? "N/A" }}</p>
                  <p class="card-text">Total Processed Amount: UGX {{
                    (this?.getDashboard?.loans?.total_amount_approved).toLocaleString() ?? "N/A" }}</p>
                  <Button to="/loans" shape="circle">
                    View Details
                    <Icon type="ios-arrow-forward"></Icon>
                  </Button>
                </div>
              </div>
            </div>

            <div v-else class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Loans
                  </h5>
                  <p class="card-text">No Loans Data Available</p>
                </div>
              </div>
            </div>

            <div v-if="this?.getDashboard?.transactions" class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Transactions
                  </h5>
                  <p class="card-text">Total Transactions: {{ this?.getDashboard?.transactions?.total_transactions ??
                    "N/A" }}</p>
                  <p class="card-text">Total Transacted Amount: UGX {{
                    (this?.getDashboard?.transactions?.total_transactions_amount).toLocaleString() ?? "N/A" }}</p>
                  <Button to="/transactions" shape="circle">
                    View Details
                    <Icon type="ios-arrow-forward"></Icon>
                  </Button>
                </div>
              </div>
            </div>

            <div v-else class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Transactions
                  </h5>
                  <p class="card-text">No Transactions Data Available</p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </Card>

    </div>


    <!-- small screens -->
    <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
      <div class="w-100 d-flex justify-content-center my-1 collapse navbar-collapse" id="navbarSupportedContent">
        <Text type="secondary">Hi {{ userIn?.biodata?.name ?? "Sacco Member" }}</Text>
      </div>


      <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
        <h1 class="navbar-brand" :style="{ 'color': '#03253D' }">&nbsp;DASHBOARD</h1>
      </nav>

      <Row class-name="m-3">
        <Col span="24">
        <Card v-if="this.getDashboard?.accounts" style="text-align:center">
          <template #title>
            <h5 class="">ACCOUNTS</h5>
          </template>
          <Card v-for="(account, idx) in this?.getDashboard?.accounts" :key="idx" style="text-align:center">
            <h5 class="">A/C No. <strong>{{ account?.account_number ?? "N/A" }}</strong></h5>
            <p class="">A/C Type: <strong>{{ account?.type ?? "N/A" }}</strong></p>
            <p class="">
              A/C Balance: <strong>{{ new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'UGX'
              }).format(account?.balance)
                ?? "N/A"
                }}</strong> </p>

            <Link to="/accounts">
            View Details
            <Icon type="ios-arrow-round-forward" />
            </Link>
          </Card>
        </Card>

        <Card v-else style="text-align:center">
          <template #title>
            <h5 class="">ACCOUNTS</h5>
          </template>
          No account information
        </Card>

        </Col>
      </Row>


      <Row class-name="m-3">
        <Col span="24">
        <Card v-if="this?.getDashboard?.shares" style="text-align:center">
          <h5 class="">SHARES</h5>
          <p class="">No. of Shares:<strong>{{ this?.getDashboard?.shares?.total_shares ?? "N/A" }}</strong> </p>
          <p class="">
            Total Share Value:
            <strong>
              {{ this?.getDashboard?.shares?.total_value ?
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'UGX'
                }).format(this?.getDashboard?.shares?.total_value)
                : "N/A"
              }}
            </strong>
          </p>

          <Link to="/shares">
          View Details
          <Icon type="ios-arrow-round-forward" />
          </Link>

        </Card>

        <Card v-else style="text-align:center">
          <template #title>
            <h5 class="">SHARES</h5>
          </template>
          No shares information
        </Card>

        </Col>
      </Row>


      <Row class-name="m-3">
        <Col span="24">
        <Card v-if="this?.getDashboard?.loans" style="text-align:center">
          <h5 class="">LOANS</h5>
          <p class="">Loan Requests:&nbsp;<strong>{{ this?.getDashboard?.loans?.number_requested ?? "N/A" }}</strong>
          </p>
          <p class="">Approved Loans:&nbsp;<strong>{{ this?.getDashboard?.loans?.number_approved ?? "N/A" }}</strong>
          </p>
          <p class="">
            Total Requested Amount:
            <strong>
              {{ this?.getDashboard?.loans?.total_amount_requested ?
                new Intl.NumberFormat('en-US', {
                }).format(this?.getDashboard?.loans?.total_amount_requested)
                : "N/A"
              }}
            </strong>
          </p>
          <p class="">
            Total Processed Amount:
            <strong>
              {{ this?.getDashboard?.loans?.total_amount_approved ?
                new Intl.NumberFormat('en-US', {
                }).format(this?.getDashboard?.loans?.total_amount_approved)
                : "N/A"
              }}
            </strong>
          </p>

          <Link to="/loans">
          View Details
          <Icon type="ios-arrow-round-forward" />
          </Link>

        </Card>

        <Card v-else style="text-align:center">
          <template #title>
            <h5 class="">LOANS</h5>
          </template>
          No loan information
        </Card>

        </Col>
      </Row>

      <Row class-name="m-3">
        <Col span="24">
        <Card v-if="this?.getDashboard?.transactions" style="text-align:center">
          <h5 class="">TRANSACTIONS</h5>
          <p class="">Total Transactions: <strong>
              {{ this?.getDashboard?.transactions?.total_transactions ?? "N/A" }}
            </strong> </p>
          <p class="">
            Total Transacted Amount:
            <strong>
              {{ this?.getDashboard?.transactions?.total_transactions_amount ?
                new Intl.NumberFormat('en-US', {
                }).format(this?.getDashboard?.transactions?.total_transactions_amount)
                : "N/A"
              }}
            </strong>
          </p>

          <Link to="/transactions">
          View Details
          <Icon type="ios-arrow-round-forward" />
          </Link>

        </Card>

        <Card v-else style="text-align:center">
          <template #title>
            <h5 class="">TRANSACTIONS</h5>
          </template>
          No transactions information
        </Card>

        </Col>
      </Row>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
    }
  },
  methods: {
    refresh() {
      this.Shares()
      this.Accounts()
      this.Loans()
      this.Trans()

      this.$store.dispatch("fetchClientDashboard");
    },
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    ...mapActions({
      Shares: 'fetchShares',
      Accounts: 'fetchAccounts',
      Trans: 'fetchTransactions',
      Loans: 'fetchLoans'
    })
  },
  computed: {
    ...mapGetters([
      'userIn', 'shareTrans', 'allAccounts', 'allLoans', 'allTrans', 'getDashboard'
    ])
  },

  created() {
    this.Shares()
    this.Accounts()
    this.Loans()
    this.Trans()

    this.$store.dispatch("fetchClientDashboard");
  }
}
</script>

<style scoped>
#home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
