<template>
  <div class="container py-3">
    <div v-if="!this?.allLoans?.results">
      <!-- <h3 class="text-primary text-center"> -->
      <!--   <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span> -->
      <!--   Please wait... -->
      <!-- </h3> -->
      <loading-view />
    </div>

    <div v-else>
      <!-- large screens  -->
      <div class="d-none d-lg-block d-xl-block">

        <div class="table-responsive bg-white">
          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-start">
            <h1 class="navbar-brand">&nbsp;LOANS</h1>
          </nav>

          <!-- <Scroll height="500"> -->
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Requested</th>
                <th scope="col">Approved</th>
                <th scope="col">Status</th>
                <th scope="col">Rate</th>
                <th scope="col">Duration</th>
                <th scope="col">Disbursement Date</th>
                <th scope="col">Balance</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody v-if="this?.allLoans?.results?.length < 1">
              <tr>
                <td> No loans yet.</td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr v-for="(loan, i) in this?.allLoans?.results" :key="i">
                <th scope="row">{{ i + 1 }}. </th>
                <td>{{ loan?.amount_requested ?? "N/A" }}</td>
                <td>{{ loan?.amount_approved ?? "N/A" }}</td>
                <td>{{ statusAsString(loan?.loan_status) ?? "N/A" }}
                </td>
                <td>{{ loan?.rate ?? "N/A" }}%</td>
                <td>{{ loan?.requested_duration ?? "N/A" }}&nbsp;{{ intervalAsString(loan?.interval) ?? "N/A" }}
                </td>
                <td>{{ loan?.schedule_start ?? "N/A" }}</td>
                <td v-if="loan?.balance" class="text-danger">{{ loan?.balance }}</td>
                <td v-else>N/A</td>
                <td>
                  <Space wrap>
                    <!-- <Button :to="{ name: 'loan-request-detail', params: { id: loanRequest.id } }"> -->
                    <!--     View -->
                    <!-- </Button> -->

                    <Button v-if="statusAsString(loan?.loan_status) == 'DISBURSED'" type="default" size="default"
                      @click="selectLoanToTopUp(loan)">
                      Top Up
                    </Button>

                  </Space>
                </td>

              </tr>
            </tbody>

            <tfoot v-if="this?.allLoans?.results?.length < 1"></tfoot>

            <tfoot v-else>
              <tr>
                <td colspan="9" class="text-center">
                  <Space type="">
                    <Page :total="this?.allLoans?.total_count" :page-size="30" v-model="loanPage" show-total
                      @on-change="handleChangeLoanPage" />
                  </Space>
                </td>
              </tr>
            </tfoot>

          </table>
          <!-- </Scroll> -->

        </div>


      </div>

      <!-- small screens -->
      <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">

        <div class="bg-white">
          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
            <h1 class="navbar-brand">&nbsp;LOANS</h1>
          </nav>

          <table class="table table-hover">
            <thead>
              <tr>
                <!-- <th scope="col">#</th> -->
                <!-- <th scope="col">Requested</th> -->
                <!-- <th scope="col">Approved</th> -->
                <!-- <th scope="col">Rate</th> -->
                <!-- <th scope="col">Duration</th> -->
                <!-- <th scope="col">Date</th> -->
                <!-- <th scope="col">Total Balance</th> -->
                <!-- <th scope="col">Summary</th> -->
              </tr>
            </thead>

            <tbody v-if="this?.allLoans?.results?.length < 1">
              <tr>
                <td> No loans yet.</td>
              </tr>
            </tbody>

            <tbody v-else>
              <!-- <tr v-for="(loan, i) in this?.allLoans?.results" :key="i"> -->
              <!--   <th scope="row">{{ i + 1 }}. </th> -->
              <!--   <td>{{ loan.amount_requested }}</td> -->
              <!--   <td>{{ loan.amount_approved }}</td> -->
              <!--   <td>{{ loan.rate }}%</td> -->
              <!--   <td>{{ loan.requested_duration }}</td> -->
              <!--   <td>{{ loan.schedule_start ?? loan.approved_on ?? loan.requested_on }}</td> -->
              <!--   <td> <strong>{{ loan.balance }}</strong> </td> -->
              <!-- </tr> -->

              <div class="w-100" v-for="(loan, idx) in this?.allLoans?.results" :key="idx">
                <div class="border-bottom">
                  <tr class='row d-flex justify-content-center align-items-center'>
                    <!-- <td class="">1. -->
                    <!-- </td> -->
                    <td class="col w-100">
                      <div class="w-100">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <div class="">
                            <h6>UGX {{ loan?.amount_approved ?? "N/A" }}</h6>
                          </div>
                          <div class="">
                            {{ loan?.approved_on ?? "N/A" }} </div>

                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="text-muted">
                            {{ statusAsString(loan?.loan_status) ?? "N/A" }}
                          </div>
                          <div class="">
                            <Button size="small" type="primary" @click="handleSelectLoan(idx)">
                              {{ "&rarr;" }}
                            </Button>
                          </div>
                        </div>
                        <p v-if="statusAsString(loan?.loan_status) === 'DISBURSED' || statusAsString(loan?.loan_status) === 'TOPPED_UP'"
                          class="text-muted">BALANCE:&nbsp;<span class="text-danger">{{ loan?.balance ?? "N/A" }}</span>
                        </p>

                      </div>
                    </td>

                  </tr>

                </div>

                <div class="container bg-light" v-if="viewLoanDetail && (selectedLoanIndex === idx)">
                  <div class="container">
                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Requested
                      </div>
                      <div class="">
                        {{ loan?.amount_requested ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Approved
                      </div>
                      <div class="">
                        {{ loan?.amount_approved ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Rate
                      </div>
                      <div class="">
                        {{ loan?.rate ?? "N/A" }}%
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Duration
                      </div>
                      <div class="">
                        {{ loan?.duration ?? "N/A" }}&nbsp;{{ intervalAsString(loan?.interval) ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Date Disbursed
                      </div>
                      <div class="">
                        {{ loan?.schedule_start ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Status
                      </div>
                      <div class="">
                        {{ statusAsString(loan?.loan_status) ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Balance
                      </div>
                      <div class="">
                        {{ loan?.balance ?? "N/A" }}
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </tbody>

            <tfoot v-if="this?.allLoans?.results?.length < 1"></tfoot>

            <tfoot v-else>
              <tr>
                <td colspan="9" class="text-center">
                  <Space type="">
                    <Page :total="this?.allLoans?.total_count" :page-size="30" v-model="loanPage" show-total
                      @on-change="handleChangeLoanPage" />
                  </Space>
                </td>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </div>
  </div>



  <Modal v-model="openLoanTopUpRequestModal" title="Request New Loan Top Up" ok-text="Submit" cancel-text="Cancel"
    @on-cancel="handleResetLoanTopUpRequest('loanTopUpFormValidate')" :loading="loadingStatus"
    @on-ok="handleSubmitLoanTopUpRequest('loanTopUpFormValidate')">
    <Form ref="loanTopUpFormValidate" :model="loanTopUpFormValidate" label-position="left" :label-width="100"
      :rules="loanTopUpRuleValidate">
      <!-- <FormItem label="Date" prop="date"> -->
      <!--   <DatePicker type="date" placeholder="Select date" v-model="loanTopUpFormValidate.date"></DatePicker> -->
      <!-- </FormItem> -->

      <FormItem label="Amount" prop="amount">
        <InputNumber :min="0" v-model="loanTopUpFormValidate.amount"
          :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          :parser="value => value.replace(/\$\s?|(,*)/g, '')" class="w-100" placeholder="10,000" />
      </FormItem>

      <!-- <FormItem label="Account" prop="account"> -->
      <!--   <Select v-model="loanTopUpFormValidate.account" filterable placeholder="Select Account..."> -->
      <!--     <Option v-for="(account, idx) in this.allAccounts" :value="account.id?.toString()" :key="idx">ACC -->
      <!--       No.&nbsp;{{ -->
      <!--         account.account_number }}</Option> -->
      <!---->
      <!--   </Select> -->
      <!-- </FormItem> -->

      <!-- <FormItem> -->
      <!--     <Row> -->
      <!--         <Col span="5"> -->
      <!--         <Button @click="handleResetWithdrawRequest('loanTopUpFormValidate')" -->
      <!--             v-if="!loadingStatus">Reset</Button> -->
      <!--         </Col> -->
      <!--         <Col span="1"> -->
      <!--         </Col> -->
      <!--         <Col span="4"> -->
      <!--         <Button type="primary" @click="handleSubmitLoanTopUpRequest('loanTopUpFormValidate')" -->
      <!--             :loading="loadingStatus"> -->
      <!--             <span v-if="!loadingStatus">Submit</span> -->
      <!--             <span v-else>Loading...</span> -->
      <!--         </Button> -->
      <!--         </Col> -->
      <!--         <Col span="8"> -->
      <!--         </Col> -->
      <!--     </Row> -->
      <!---->
      <!-- </FormItem> -->
    </Form>
  </Modal>



</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import LoadingView from "../components/Loading.vue"

export default {
  name: "LoansView",
  data() {
    return {
      loanPage: 1,
      viewLoanDetail: false,
      selectedLoanIndex: null,

      selectedLoan: {},
      openLoanTopUpRequestModal: false,
      loanTopUpFormValidate: {
        date: new Date(Date.now()),
        amount: '',
        // account: '',
      },
      loanTopUpRuleValidate: {
        // date: [
        //   { required: true, type: 'date', message: 'Please select the date', trigger: 'change' }
        // ],
        amount: [
          { required: true, message: 'Amount must be more than 0', trigger: 'change', type: 'number' }
        ],
        // account: [
        //   { required: true, message: 'Select valid account', trigger: 'change', type: 'string' },
        // ],

      },
      loadingStatus: false,

    }
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    handleChangeLoanPage(value) {
      this.$store
        .dispatch('fetchLoans', { page: value })
    },
    handleSelectLoan(idx) {

      this.selectedLoanIndex = idx;

      this.viewLoanDetail = !this.viewLoanDetail;

    },
    statusAsString(statusNumber) {
      let status = null;

      switch (statusNumber) {

        case 0:
          status = 'APPLIED';
          break;

        case 1:
          status = 'APPRAISED';
          break;

        case 2:
          status = 'APPROVED';
          break;

        case 3:
          status = 'DISBURSED';
          break;

        case 4:
          status = 'CLOSED';
          break;

        case 5:
          status = 'REJECTED';
          break;

        case 6:
          status = 'WAIVED_OFF';
          break;

        case 7:
          status = 'WRITTEN_OFF';
          break;

        case 8:
          status = 'TOPPED_UP';
          break;

        default:
          status = null;
          break;
      }

      return status;

    },
    intervalAsString(intervalNumber) {
      let interval = null;

      switch (intervalNumber) {

        case 0:
          interval = 'DAYS';
          break;

        case 1:
          interval = 'WEEKS';
          break;

        case 2:
          interval = 'FORTNIGHT';
          break;

        case 3:
          interval = 'MONTHS';
          break;

        case 4:
          interval = 'YEARS';
          break;

        default:
          interval = null;
          break;
      }

      return interval;

    },
    rateAsString(rateNumber) {
      let rate = null;

      switch (rateNumber) {

        case 0:
          rate = "anum";
          break;

        default:
          rate = null;
          break;
      }

      return rate;

    },
    selectLoanToTopUp(loan) {
      this.selectedLoan = loan;

      this.openLoanTopUpRequestModal = true;

    },
    handleSubmitLoanTopUpRequest(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loadingStatus = true;

          this.$Loading.start()

          // this.$Message.success('Success!');
          this.$store
            .dispatch(
              'requestLoanTopUp',
              { ...this.loanTopUpFormValidate, loan: this.selectedLoan?.id }
            )
            .then(() => {
              this.$Message.success('Successfully requested for loan top up!');

              setTimeout(() => {
                this.$Loading.finish()

                this.loadingStatus = false;

                this.$refs[name].resetFields();

                this.openLoanTopUpRequestModal = false;

                this.$store
                  .dispatch('fetchLoanTopUps')

                // this.$router.go(0)
              }, 1000);
            })
            .catch(err => {
              this.$Loading.error()

              this.$Message.error(`Error: ${err?.response?.data?.message ?? err.message}`);

              this.loadingStatus = false;
            })
        } else {
          this.$Message.error('Some fields are missing!');
        }
      })

      // console.log(this.withdrawFormValidate)

    },
    handleResetLoanTopUpRequest(name) {
      this.$refs[name].resetFields();
    },

    ...mapActions({
      LoanTopUps: 'fetchLoanTopUps',
    }),
    moment: function () {
      return moment();
    },
  },
  computed: {
    ...mapGetters([
      'getLoanTopUps',
      'allLoans',
    ])
  },
  created() {
    this.$store
      .dispatch('fetchLoans', { page: this.loanPage })

    // this.Loans({ page: this.loanPage }).then(() => { })
    //   .catch(err => {
    //     if (err.code == 'ERR_NETWORK') {
    //       this.error(err.code, err.message)
    //     } else {
    //       this.error(err.code, '')
    //     }
    //   })
  },
  components: {
    LoadingView,
  },
}
</script>

<style scoped></style>
