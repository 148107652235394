<template>
  <!-- large screens  -->
  <div class="d-none d-lg-block d-xl-block">
    <div class="">

      <nav class="navbar navbar-expand-lg navbar-light bg-light container d-flex justify-content-between">
        <div class="">
          <h1 class="navbar-brand">STATEMENT</h1>
        </div>

        <div class="" id="navbarSupportedContent">

          <Space wrap class="">
            <Form ref="statementFormValidate" :model="statementFormValidate" :rules="statementRuleValidate" inline>
              <FormItem label="Start Date" prop="start_date">
                <DatePicker type="date" placeholder="Start date" v-model="statementFormValidate.start_date"
                  :options="datePickerOptions"></DatePicker>
              </FormItem>

              <FormItem label="End Date" prop="end_date">
                <DatePicker type="date" placeholder="End date" v-model="statementFormValidate.end_date"
                  :options="datePickerOptions"></DatePicker>
              </FormItem>

              <FormItem label="Account" prop="account">
                <Select placeholder="Select" prop="account" v-model="statementFormValidate.account"
                  :style="{ 'width': '200px' }">
                  <Option v-for="account in this.allAccounts" :key="account.id" :value="account.id?.toString()">
                    {{ account.account_number }} ({{ account.type }})
                  </Option>
                </Select>
              </FormItem>

              <FormItem label="Export">
                <Button @click="downloadStatement" icon="ios-cloud-download-outline">
                </Button>
              </FormItem>
            </Form>

          </Space>

        </div>
      </nav>

      <div class="container bg-white">

        <div class="text-center pt-3">
          <p class="fw-bold" v-if="accountStatement && !loading">Account Statement for the Period between
            {{ accountStatement.start_date }} and {{ accountStatement.end_date }}</p>
        </div>

        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Transaction</th>
              <th scope="col">Amount Dr</th>
              <th scope="col">Amount Cr</th>
              <th scope="col">Running Balance</th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="loading === true">
              <td colspan="5" class="text-center">Loading...</td>
            </tr>


            <tr v-for="(item, i) in accountStatement.individual_gl" :key="i">
              <td>{{ item.gl ? item.gl.tx_date : "N/A" }}</td>
              <td>
                <span v-if="item.is_opening_balance">Opening Balance</span>
                <span v-else>{{ item.gl ? item.gl.transaction : "N/A" }}</span>
              </td>
              <td>{{ new Intl.NumberFormat('en-US', {
              }).format(item.amount_dr,) ?? "N/A" }} </td>
              <td>{{ new Intl.NumberFormat('en-US', {
              }).format(item.amount_cr,) ?? "N/A" }} </td>
              <td>
                <span v-if="item.balance < 0" class="text-danger">{{ new Intl.NumberFormat('en-US', {
                }).format(item.balance,) ?? "N/A" }}</span>
                <span v-else class="text-dark">{{ new Intl.NumberFormat('en-US', {
                }).format(item.balance,) ?? "N/A" }}</span>
              </td>
            </tr>

            <tr v-if="accountStatement?.individual_gl?.length < 1">
              <td colspan="5" class="text-center">No Data Available</td>
            </tr>

          </tbody>

          <tfoot>
            <tr v-if="accountStatement?.individual_gl?.length > 0">
              <td class="text-dark fw-bold">TOTALS</td>
              <td></td>
              <td class="text-dark fw-bold">{{ accountStatement.total_dr ? new Intl.NumberFormat('en-US', {
              }).format(accountStatement.total_dr,) : "N/A" }} </td>
              <td class="text-dark fw-bold">{{ accountStatement.total_cr ? new Intl.NumberFormat('en-US', {
              }).format(accountStatement.total_cr,) : "N/A" }} </td>
              <td class="underline">
                <span v-if="accountStatement.total_cr - accountStatement.total_dr < 0" class="text-danger fw-bold">
                  -{{ accountStatement.total_dr - accountStatement.total_cr }}
                </span>
                <span v-else class="text-dark fw-bold">
                  {{ (accountStatement.total_cr - accountStatement.total_dr) ? new Intl.NumberFormat('en-US', {
                  }).format((accountStatement.total_cr - accountStatement.total_dr),) : "N/A" }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>

      </div>
    </div>

    <!-- <div class="container py-3"> -->
    <!--   <div class="row text-center"> -->
    <!--     <div class="col"> -->
    <!--       <div class="mb-3 row"> -->
    <!--         <label for="start-date" class="col col-form-label d-none d-lg-block">Start Date</label> -->
    <!--         <div class="col"> -->
    <!--           <input type="date" @change="filterStatement" v-model="filter.start_date" class="form-control" -->
    <!--             id="start-date"> -->
    <!--         </div> -->
    <!--       </div> -->
    <!--     </div> -->
    <!---->
    <!--     <div class="col"> -->
    <!--       <div class="mb-3 row"> -->
    <!--         <label for="end-date" class="col col-form-label d-none d-lg-block">End Date</label> -->
    <!--         <div class="col"> -->
    <!--           <input type="date" @change="filterStatement" v-model="filter.end_date" class="form-control" id="end-date"> -->
    <!--         </div> -->
    <!--       </div> -->
    <!--     </div> -->
    <!---->
    <!--     <div class="col"> -->
    <!--       <div class="mb-3 row"> -->
    <!--         <label for="account" class="col col-form-label d-none d-lg-block">Account</label> -->
    <!--         <div class="col"> -->
    <!--           <select class="form-control" @change="filterStatement" v-model="filter.account" id="account"> -->
    <!--             <option value="0"> Select Account </option> -->
    <!--             <option v-for="account in this.allAccounts" :key="account.id" :value="account.id"> -->
    <!--               {{ account.account_number }} ({{ account.type }}) -->
    <!--             </option> -->
    <!--           </select> -->
    <!--         </div> -->
    <!--       </div> -->
    <!--     </div> -->
    <!---->
    <!--     <div class="alert alert-warning fade show" role="alert" v-if="errors"> -->
    <!--       <strong>{{ errors.title }}!</strong> {{ errors.message }}. -->
    <!--     </div> -->
    <!---->
    <!--     <div v-if="loading"> -->
    <!--       <h3 class="text-primary text-center"> -->
    <!--         <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span> -->
    <!--         Please wait... -->
    <!--       </h3> -->
    <!--     </div> -->
    <!---->
    <!--     <p class="fw-bold" v-if="accountStatement && !loading">Account Statement for the Period between -->
    <!--       {{ accountStatement.start_date }} and {{ accountStatement.end_date }}</p> -->
    <!--     <hr class="my-3"> -->
    <!--   </div> -->
    <!---->
    <!--   <div v-if="!loading"> -->
    <!--     <div class="table-responsive" v-if="accountStatement"> -->
    <!--       <table class="table table-hover"> -->
    <!--         <thead> -->
    <!--           <tr> -->
    <!--             <th scope="col">Date</th> -->
    <!--             <th scope="col">Transaction</th> -->
    <!--             <th scope="col">Amount Dr</th> -->
    <!--             <th scope="col">Amount Cr</th> -->
    <!--             <th scope="col">Running Balance</th> -->
    <!--           </tr> -->
    <!--         </thead> -->
    <!--         <tbody> -->
    <!--           <tr v-for="(item, i) in accountStatement.individual_gl" :key="i"> -->
    <!--             <td>{{ item.gl ? item.gl.tx_date : "" }}</td> -->
    <!--             <td> -->
    <!--               <span v-if="item.is_opening_balance">Opening Balance</span> -->
    <!--               <span v-else>{{ item.gl ? item.gl.transaction : "" }}</span> -->
    <!--             </td> -->
    <!--             <td>{{ new Intl.NumberFormat('en-US', { -->
    <!--               style: 'currency', -->
    <!--               currency: 'UGX' -->
    <!--             }).format(item.amount_dr,) }} </td> -->
    <!--             <td>{{ new Intl.NumberFormat('en-US', { -->
    <!--               style: 'currency', -->
    <!--               currency: 'UGX' -->
    <!--             }).format(item.amount_cr,) }} </td> -->
    <!--             <td> -->
    <!--               <span v-if="item.balance < 0" class="text-danger">{{ new Intl.NumberFormat('en-US', { -->
    <!--                 style: 'currency', -->
    <!--                 currency: 'UGX' -->
    <!--               }).format(item.balance,) }}</span> -->
    <!--               <span v-else class="text-dark">{{ new Intl.NumberFormat('en-US', { -->
    <!--                 style: 'currency', -->
    <!--                 currency: 'UGX' -->
    <!--               }).format(item.balance,) }}</span> -->
    <!--             </td> -->
    <!--           </tr> -->
    <!--         </tbody> -->
    <!--         <tfoot> -->
    <!--           <tr> -->
    <!--             <td class="text-dark fw-bold">TOTALS</td> -->
    <!--             <td></td> -->
    <!--             <td class="text-dark fw-bold">{{ accountStatement.total_dr ? new Intl.NumberFormat('en-US', { -->
    <!--               style: 'currency', -->
    <!--               currency: 'UGX' -->
    <!--             }).format(accountStatement.total_dr,) : "N/A" }} </td> -->
    <!--             <td class="text-dark fw-bold">{{ accountStatement.total_cr ? new Intl.NumberFormat('en-US', { -->
    <!--               style: 'currency', -->
    <!--               currency: 'UGX' -->
    <!--             }).format(accountStatement.total_cr,) : "N/A" }} </td> -->
    <!--             <td class="underline"> -->
    <!--               <span v-if="accountStatement.total_cr - accountStatement.total_dr < 0" class="text-danger fw-bold"> -->
    <!--                 -{{ accountStatement.total_dr - accountStatement.total_cr }} -->
    <!--               </span> -->
    <!--               <span v-else class="text-dark fw-bold"> -->
    <!--                 {{ (accountStatement.total_cr - accountStatement.total_dr) ? new Intl.NumberFormat('en-US', { -->
    <!--                   style: 'currency', -->
    <!--                   currency: 'UGX' -->
    <!--                 }).format((accountStatement.total_cr - accountStatement.total_dr),) : "N/A" }} -->
    <!--               </span> -->
    <!--             </td> -->
    <!--           </tr> -->
    <!--         </tfoot> -->
    <!--       </table> -->
    <!--     </div> -->
    <!--     <div v-else> -->
    <!--       <div style="text-align: center;"> -->
    <!--         <h4>Pick Start and End date to see the Statement</h4> -->
    <!--       </div> -->
    <!--     </div> -->
    <!--   </div> -->
    <!-- </div> -->

  </div>


  <!-- small screens -->
  <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
    <div class="">

      <nav class="navbar navbar-expand-lg navbar-light bg-light container d-flex justify-content-center">
        <div class="">
          <h1 class="navbar-brand">STATEMENT</h1>
        </div>

        <div class="" id="navbarSupportedContent">

        </div>
      </nav>

      <Form ref="statementFormValidate" :model="statementFormValidate" :rules="statementRuleValidate" inline
        class="w-100">
        <FormItem label="Start Date" prop="start_date" class="w-100 d-flex justify-content-between">
          <DatePicker type="date" placeholder="Start date" v-model="statementFormValidate.start_date"
            :options="datePickerOptions"></DatePicker>
        </FormItem>

        <FormItem label="End Date" prop="end_date" class="w-100 d-flex justify-content-between">
          <DatePicker type="date" placeholder="End date" v-model="statementFormValidate.end_date"
            :options="datePickerOptions"></DatePicker>
        </FormItem>

        <FormItem label="Account" prop="account" class="w-100 d-flex justify-content-between">
          <Select placeholder="Select" prop="account" v-model="statementFormValidate.account"
            :style="{ 'width': '200px' }">
            <Option v-for="account in this.allAccounts" :key="account.id" :value="account.id?.toString()">
              {{ account.account_number }} ({{ account.type }})
            </Option>
          </Select>
        </FormItem>

        <FormItem label="Export" class="w-100 d-flex justify-content-around">
          <Button @click="downloadStatement" icon="ios-cloud-download-outline">
          </Button>
        </FormItem>
      </Form>



      <div class="container bg-white table-responsive">

        <div class="text-center pt-3">
          <p class="fw-bold" v-if="accountStatement && !loading">Account Statement for the Period between
            {{ accountStatement.start_date }} and {{ accountStatement.end_date }}</p>
        </div>

        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">TXN</th>
              <th scope="col">Amount Dr</th>
              <th scope="col">Amount Cr</th>
              <th scope="col">Run. Bal.</th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="loading === true">
              <td colspan="5" class="text-center">Loading...</td>
            </tr>


            <tr v-for="(item, i) in accountStatement.individual_gl" :key="i">
              <td>{{ item.gl ? item.gl.tx_date : "N/A" }}</td>
              <td>
                <span v-if="item.is_opening_balance">Opening Balance</span>
                <span v-else>{{ item.gl ? item.gl.transaction : "N/A" }}</span>
              </td>
              <td>{{ new Intl.NumberFormat('en-US', {
              }).format(item.amount_dr,) ?? "N/A" }} </td>
              <td>{{ new Intl.NumberFormat('en-US', {
              }).format(item.amount_cr,) ?? "N/A" }} </td>
              <td>
                <span v-if="item.balance < 0" class="text-danger">{{ new Intl.NumberFormat('en-US', {
                }).format(item.balance,) ?? "N/A" }}</span>
                <span v-else class="text-dark">{{ new Intl.NumberFormat('en-US', {
                }).format(item.balance,) ?? "N/A" }}</span>
              </td>
            </tr>

            <tr v-if="accountStatement?.individual_gl?.length < 1">
              <td colspan="5" class="text-center">No Data Available</td>
            </tr>

          </tbody>

          <tfoot>
            <tr v-if="accountStatement?.individual_gl?.length > 0">
              <td class="text-dark fw-bold">TOTALS</td>
              <td></td>
              <td class="text-dark fw-bold">{{ accountStatement.total_dr ? new Intl.NumberFormat('en-US', {
              }).format(accountStatement.total_dr,) : "N/A" }} </td>
              <td class="text-dark fw-bold">{{ accountStatement.total_cr ? new Intl.NumberFormat('en-US', {
              }).format(accountStatement.total_cr,) : "N/A" }} </td>
              <td class="underline">
                <span v-if="accountStatement.total_cr - accountStatement.total_dr < 0" class="text-danger fw-bold">
                  -{{ accountStatement.total_dr - accountStatement.total_cr }}
                </span>
                <span v-else class="text-dark fw-bold">
                  {{ (accountStatement.total_cr - accountStatement.total_dr) ? new Intl.NumberFormat('en-US', {
                  }).format((accountStatement.total_cr - accountStatement.total_dr),) : "N/A" }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>

      </div>
    </div>

    <!--   <div class="container py-3"> -->
    <!---->
    <!--     <div class="w-100 "> -->
    <!--       <div class="w-100 px-1"> -->
    <!--         <label for="start-date" class="">Start Date</label> -->
    <!--         <input type="date" @change="filterStatement" v-model="filter.start_date" class="form-control" id="start-date"> -->
    <!--       </div> -->
    <!---->
    <!--       <div class="w-100 px-1"> -->
    <!--         <label for="end-date" class="">End Date</label> -->
    <!--         <input type="date" @change="filterStatement" v-model="filter.end_date" class="form-control" id="end-date" -->
    <!--           placeholder="End Date"> -->
    <!--       </div> -->
    <!---->
    <!--       <div class="w-100 px-1"> -->
    <!--         <label for="account" class="">Account</label> -->
    <!--         <select v-if="this?.allAccounts?.length > 1" class="form-control" @change="filterStatement" -->
    <!--           v-model="filter.account" id="account"> -->
    <!--           <option value="0" :disabled="true"> Select Account </option> -->
    <!--           <option v-for="account in this.allAccounts" :key="account.id" :value="account.id"> -->
    <!--             {{ account.account_number }} ({{ account.type }}) -->
    <!--           </option> -->
    <!--         </select> -->
    <!---->
    <!--         <select v-else class="form-control" @change="filterStatement" v-model="filter.account" id="account"> -->
    <!--           <option value="0" :disabled="true"> Select Account </option> -->
    <!--           <option v-for="account in this.allAccounts" :key="account.id" :value="account.id" :default="true" -->
    <!--             :selected="true"> -->
    <!--             {{ account.account_number }} ({{ account.type }}) -->
    <!--           </option> -->
    <!--         </select> -->
    <!---->
    <!--       </div> -->
    <!--     </div> -->
    <!---->
    <!--     <div class="row text-center"> -->
    <!--       <div v-if="loading"> -->
    <!--         <h3 class="text-primary text-center"> -->
    <!--           <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span> -->
    <!--         </h3> -->
    <!--       </div> -->
    <!---->
    <!--       <p class="fw-bold" v-if="accountStatement && !loading">Account Statement for the Period between -->
    <!--         {{ accountStatement.start_date }} and {{ accountStatement.end_date }}</p> -->
    <!---->
    <!--       <hr class="my-3"> -->
    <!--     </div> -->
    <!---->
    <!--     <div v-if="!loading"> -->
    <!--       <div class="table-responsive bg-white" v-if="accountStatement"> -->
    <!--         <table class="table table-hover"> -->
    <!--           <thead> -->
    <!--             <tr> -->
    <!--               <th scope="col">Date</th> -->
    <!--               <th scope="col">TXN</th> -->
    <!--               <th scope="col">Amt Dr</th> -->
    <!--               <th scope="col">Amt Cr</th> -->
    <!--               <th scope="col">Running Balance</th> -->
    <!--             </tr> -->
    <!--           </thead> -->
    <!---->
    <!--           <tbody> -->
    <!--             <tr v-for="(item, i) in accountStatement.individual_gl" :key="i"> -->
    <!--               <td>{{ item.gl ? item.gl.tx_date : "N/A" }}</td> -->
    <!--               <td> -->
    <!--                 <span v-if="item.is_opening_balance">Opening Balance</span> -->
    <!--                 <span v-else>{{ item.gl ? item.gl.transaction : "N/A" }}</span> -->
    <!--               </td> -->
    <!--               <td>{{ item?.amount_dr ?? "N/A" }} </td> -->
    <!--               <td>{{ item?.amount_cr ?? "N/A" }} </td> -->
    <!--               <td> -->
    <!--                 <span v-if="item.balance < 0" class="text-danger">{{ item?.balance ?? "N/A" }}</span> -->
    <!--                 <span v-else class="text-dark">{{ item?.balance ?? "N/A" }}</span> -->
    <!--               </td> -->
    <!--             </tr> -->
    <!--           </tbody> -->
    <!---->
    <!--           <tfoot> -->
    <!--             <tr> -->
    <!--               <td class="text-dark fw-bold">TOTALS</td> -->
    <!--               <td></td> -->
    <!--               <td class="text-dark fw-bold">{{ accountStatement?.total_dr ?? "N/A" }} </td> -->
    <!--               <td class="text-dark fw-bold">{{ accountStatement?.total_cr ?? "N/A" }} </td> -->
    <!--               <td class="underline"> -->
    <!--                 <span v-if="accountStatement.total_cr - accountStatement.total_dr < 0" class="text-danger fw-bold"> -->
    <!--                   -{{ accountStatement.total_dr - accountStatement.total_cr }} -->
    <!--                 </span> -->
    <!--                 <span v-else class="text-dark fw-bold"> -->
    <!--                   {{ (accountStatement.total_cr - accountStatement.total_dr) ? new Intl.NumberFormat('en-US', { -->
    <!--                     style: 'currency', -->
    <!--                     currency: 'UGX' -->
    <!--                   }).format((accountStatement.total_cr - accountStatement.total_dr),) : "N/A" }} -->
    <!--                 </span> -->
    <!--               </td> -->
    <!--             </tr> -->
    <!--           </tfoot> -->
    <!--         </table> -->
    <!--       </div> -->
    <!---->
    <!--       <div v-else> -->
    <!--         <div class="alert alert-warning fade show" role="alert" v-if="errors"> -->
    <!--           <strong>{{ errors.title }}!</strong> {{ errors.message }}. -->
    <!--         </div> -->
    <!---->
    <!--         <div style="text-align: center;"> -->
    <!--           <h4>Pick Start and End date to see the Statement</h4> -->
    <!--         </div> -->
    <!--       </div> -->
    <!---->
    <!--     </div> -->
    <!--   </div> -->
    <!---->
  </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { API_URL } from "./../config/api.config.js"

export default {
  name: "TransactionsView",

  data() {
    return {
      loading: false,
      filter: {
        business: "",
        account: "0",
        start_date: "",
        end_date: "",
      },
      errors: "",

      statementFormValidate: {
        start_date: '',
        end_date: '',
        account: '',
      },
      statementRuleValidate: {
        start_date: [
          { required: true, type: 'date', message: 'Please select the start date', trigger: 'change' }
        ],
        end_date: [
          { required: true, type: 'date', message: 'Please select the end date', trigger: 'change' }
        ],
        account: [
          { required: true, message: 'Select valid account', trigger: 'change' },
        ],
      },
      statementDownloadURL: `${API_URL}/export-member-statement/`,

    }
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },

    filterStatement() {
      this.loading = true
      if (this.filter.start_date === "" || this.filter.start_date.length < 1) {
        this.errors = { title: 'Start Date unknown', message: 'Please pick the starting date of the statement' }
        this.loading = false
        return
      } else {
        this.errors = ""
      }
      if (this.filter.end_date === "" || this.filter.end_date.length < 1) {
        this.errors = { title: 'End Date unknown', message: 'Please pick the ending date of the statement' }
        this.loading = false
        return
      } else {
        this.errors = ""
      }

      if (this.filter.account === "0" || this.filter.account === "") {
        this.errors = { title: 'Account unknown', message: 'Please select an account to view statement' }
        this.loading = false
        return
      } else {
        this.errors = ""
      }

      if (this.filter.business === "" || this.filter.business.length < 1) {
        this.errors = { title: 'Internal Server Error', message: 'Please refresh this page and try again' }
        this.loading = false
        return
      } else {
        this.errors = ""
      }


      this.$store.dispatch('fetchStatement', this.filter).then(() => { this.loading = false }).catch(() => { this.loading = false })
    },
    setBusiness() {
      this.filter.business = this.userIn.business.id
    },
    downloadStatement() {

      if (this.statementFormValidate.start_date === "" || this.statementFormValidate.end_date === "" || this.statementFormValidate.account === "" || this.statementFormValidate.end_date < this.statementFormValidate.start_date) {
        this.$Message.error("Please select a valid start date, end date and account to download a statement.");

        return;
      }

      this.loading = true;

      this.$store
        .dispatch('downloadStatement', {
          "business": this.filter.business,
          "start_date": (new Date(this.statementFormValidate.start_date)).toISOString().split("T")[0],
          "end_date": (new Date(this.statementFormValidate.end_date)).toISOString().split("T")[0],
          "account": this.statementFormValidate.account
        })
        .then((response) => {
          this.loading = false;

          const blob = new Blob([response.data], {
            type: response.data.type,
          });

          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");

          link.href = url;

          const contentDisposition = response.headers["content-disposition"];
          // console.log(contentDisposition);

          let fileName = `Statement from ${(new Date(this.statementFormValidate.start_date)).toISOString().split("T")[0]} to ${(new Date(this.statementFormValidate.end_date)).toISOString().split("T")[0]}.pdf`;

          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);

            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }

          link.setAttribute("download", fileName);

          document.body.appendChild(link);

          link.click();

          link.remove();

          window.URL.revokeObjectURL(url);

        })
        .catch(() => {
          this.loading = false;
          this.$Message.error("Failed to export transactions")
        })



    },
    ...mapActions({
      Accounts: 'fetchAccounts'
    })
  },
  computed: {
    ...mapGetters([
      'accountStatement', 'userIn', 'allAccounts'
    ])
  },
  watch: {
    userIn(newVal) {
      if (newVal) {
        this.setBusiness()
      }
    },
    statementFormValidate: {
      handler(newStatementFormValidate/* , oldStatementFormValidate */) {
        // console.log(newStatementFormValidate, oldStatementFormValidate)

        if (newStatementFormValidate.start_date === "" || newStatementFormValidate.end_date === "" || newStatementFormValidate.account === "" || newStatementFormValidate.end_date < newStatementFormValidate.start_date) {
          this.$Message.error("Please select a valid start date, end date and account to view a statement.");

          return;
        }

        this.loading = true;

        this.$store
          .dispatch('fetchStatement', {
            "business": this.filter.business,
            "start_date": (new Date(newStatementFormValidate.start_date)).toISOString().split("T")[0],
            "end_date": (new Date(newStatementFormValidate.end_date)).toISOString().split("T")[0],
            "account": newStatementFormValidate.account
          })
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          })


      },
      deep: true
    },

  },

  created() {
    this.Accounts()

    this.$store
      .dispatch('fetchAccounts')
      .then((response) => {
        this.statementFormValidate.account = `${response?.data?.response?.accounts[0]?.id}`
      })
    setTimeout(() => this.setBusiness(), 1000);
  },
}
</script>

<style scoped>
.underline {
  border-bottom: double;
}
</style>
