<template>
  <Result title="Loading...">
    <template #desc>
      Please wait...
    </template>
    <!-- <template #actions> -->
    <!--     <Button type="primary">返回修改</Button> -->
    <!-- </template> -->
    <!-- <template #extra> -->
    <!--   <Image :src="Image" lazy /> -->
    <!-- </template> -->
  </Result>
</template>

<script>
// import Image from "@/assets/new/icon_logo_light_theme.png"

export default {
  name: "LoadingView",
}
</script>
