<template>
  <div class="container py-3">
    <div v-if="!getWithdrawRequests">
      <h3 class="text-primary text-center">
        <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        Please wait...
      </h3>
    </div>

    <div class="table-responsive bg-white" v-else>

      <!-- large devices -->
      <nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-flex justify-content-center">
        <div class="w-50">
          <h1 class="navbar-brand" href="#">&nbsp;WITHDRAW REQUESTS</h1>
        </div>

        <div class="w-50 d-flex justify-content-end collapse navbar-collapse" id="navbarSupportedContent">

          <Button type="secondary" @click="openWithdrawModal = true">
            NEW WITHDRAW REQUEST
          </button>

        </div>
      </nav>

      <!-- small devices  -->
      <nav class="navbar navbar-expand-lg navbar-light bg-light d-lg-none">
        <div class="w-100 text-center">
          <h1 class="navbar-brand">WITHDRAW REQUESTS</h1>
        </div>

        <div class="w-100 d-flex justify-content-center align-items-center">

          <Button type="default" size="default" @click="openWithdrawModal = true">
            NEW REQUEST
          </button>


        </div>
      </nav>

      <!-- large devices -->
      <table v-if="getWithdrawRequests?.results?.length > 0" class="table table-hover d-none d-lg-table ">

        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Created</th>
            <th scope="col">Amount</th>
            <th scope="col">Account Number</th>
            <th scope="col">Status</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(withdrawRequest, i) in this.getWithdrawRequests.results" :key="i">
            <td>{{ this.withdrawRequestPage === 1 ? i + 1 : ((this.withdrawRequestPage - 1) * 30) + i + 1
              }}. </td>
            <td>{{ new Date(withdrawRequest?.last_modified).toDateString() ?? "N/A" }} </td>
            <td>{{ new Intl.NumberFormat('en-US', {
            }).format(withdrawRequest?.amount) }}</td>
            <td>{{ withdrawRequest?.account?.acc_number ?? "N/A" }} </td>
            <td>
              {{ withdrawRequest?.is_approved }}
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="5">
              <Space type="flex">
                <Page :total="getWithdrawRequests.total_count" :page-size="30" v-model="withdrawRequestPage" show-total
                  @on-change="handleChangeWithdrawRequestPage" />
              </Space>
            </td>
          </tr>
        </tfoot>

      </table>

      <table v-else class="table table-hover d-none d-lg-table ">

        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Created</th>
            <th scope="col">Amount</th>
            <th scope="col">Account Number</th>
            <th scope="col">Status</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="5" class="text-center">
              No Data Available
            </td>
          </tr>
        </tbody>
      </table>


      <!-- smaller devices -->
      <table v-if="getWithdrawRequests?.results?.length > 0" class="table table-hover d-lg-none ">

        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Created</th>
            <th scope="col">Amount</th>
            <!-- <th scope="col">Account Number</th> -->
            <th scope="col">Status</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(withdrawRequest, i) in this.getWithdrawRequests.results" :key="i">
            <td>{{ this.withdrawRequestPage === 1 ? i + 1 : ((this.withdrawRequestPage - 1) * 30) + i + 1
              }}. </td>
            <td>{{ new Date(withdrawRequest?.last_modified).toDateString() ?? "N/A" }} </td>
            <td>{{ new Intl.NumberFormat('en-US', {
            }).format(withdrawRequest?.amount) ?? "N/A" }}
            </td>
            <!-- <td>{{ withdrawRequest?.account?.acc_number ?? "N/A" }} </td> -->
            <td>
              {{ withdrawRequest?.is_approved }}
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="5">
              <Space type="flex">
                <Page :total="getWithdrawRequests.total_count" :page-size="30" v-model="withdrawRequestPage" show-total
                  @on-change="handleChangeWithdrawRequestPage" />
              </Space>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <Modal v-model="openWithdrawModal" title="Request New Withdrawal" ok-text="Submit" cancel-text="Cancel"
    @on-cancel="handleResetWithdrawRequest('withdrawFormValidate')" :loading="loadingStatus"
    @on-ok="handleSubmitWithdrawRequest('withdrawFormValidate')">
    <Form ref="withdrawFormValidate" :model="withdrawFormValidate" label-position="left" :label-width="100"
      :rules="withdrawRuleValidate">
      <FormItem label="Date" prop="date">
        <DatePicker type="date" placeholder="Select date" v-model="withdrawFormValidate.date"></DatePicker>
      </FormItem>

      <FormItem label="Amount" prop="amount">
        <InputNumber :min="0" v-model="withdrawFormValidate.amount"
          :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          :parser="value => value.replace(/\$\s?|(,*)/g, '')" class="w-100" placeholder="10,000" />
      </FormItem>

      <FormItem label="Account" prop="account">
        <Select v-model="withdrawFormValidate.account" filterable placeholder="Select Account...">
          <Option v-for="(account, idx) in this.allAccounts" :value="account.id?.toString()" :key="idx">ACC
            No.&nbsp;{{
              account.account_number }}</Option>

        </Select>
      </FormItem>

      <!-- <FormItem> -->
      <!--     <Row> -->
      <!--         <Col span="5"> -->
      <!--         <Button @click="handleResetWithdrawRequest('withdrawFormValidate')" -->
      <!--             v-if="!loadingStatus">Reset</Button> -->
      <!--         </Col> -->
      <!--         <Col span="1"> -->
      <!--         </Col> -->
      <!--         <Col span="4"> -->
      <!--         <Button type="primary" @click="handleSubmitWithdrawRequest('withdrawFormValidate')" -->
      <!--             :loading="loadingStatus"> -->
      <!--             <span v-if="!loadingStatus">Submit</span> -->
      <!--             <span v-else>Loading...</span> -->
      <!--         </Button> -->
      <!--         </Col> -->
      <!--         <Col span="8"> -->
      <!--         </Col> -->
      <!--     </Row> -->
      <!---->
      <!-- </FormItem> -->
    </Form>
  </Modal>


  <Modal v-model="openDeleteModal" title="Delete Action" footer-hide=true>
    <Result type="error" title="Are your sure you want to perform this action?">
      <template #desc>
        Delete Loan Request
      </template>
      <template #extra>
        <!-- <div> suree</div> -->
        <div>
          <Icon type="ios-close-circle-outline" color="#ed4014" />
          Clicking the "Continue" button will delete this record.
          <!-- <a class="ivu-ml-16"> -->
          <!--     This will remove xyz. <Icon type="ios-arrow-forward" /> -->
          <!-- </a> -->
        </div>
      </template>

      <template #actions>
        <Button type="error" @click="deleteSelectedLoanRequest(this.selectedLoanRequest)">Continue</Button>
      </template>
    </Result>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WithdrawRequestsView",
  data() {
    return {
      queryParams: {},
      modal: false,
      openWithdrawModal: false,
      openDeleteModal: false,

      withdrawFormValidate: {
        date: '',
        amount: '',
        account: '',
      },
      withdrawRuleValidate: {
        date: [
          { required: true, type: 'date', message: 'Please select the date', trigger: 'change' }
        ],
        amount: [
          { required: true, message: 'Amount must be more than 0', trigger: 'change', type: 'number' }
        ],
        account: [
          { required: true, message: 'Select valid account', trigger: 'change', type: 'string' },
        ],

      },
      loadingStatus: false,
      // securities: [{ securityFile: null, value: 0, name: "", description: "" }],
      // guarantorList: [],
      guarantorSearchLoading: false,
      selectedLoanRequest: {},

      loanRequestPage: 1,
      withdrawRequestPage: 1,




      datePickerOptions: {
        disabledDate(date) {
          const disabledDay = date.getDate();

          return disabledDay !== (new Date()).getDate();
        }
      },
    }
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    ...mapActions({
      fetchWithdrawRequests: 'fetchWithdrawRequests',
      fetchAccounts: 'fetchAccounts',
      fetchBusinessLoanTypes: 'fetchBusinessLoanTypes',
    }),
    handleSubmitWithdrawRequest(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loadingStatus = true;

          this.$Loading.start()

          // this.$Message.success('Success!');
          this.$store
            .dispatch('requestNewWithdrawal', this.withdrawFormValidate)
            .then(() => {
              this.$Message.success('Successfully requested for withdraw!');

              setTimeout(() => {
                this.$Loading.finish()

                this.loadingStatus = false;

                this.$refs[name].resetFields();

                this.openWithdrawModal = false;

                this.$store
                  .dispatch('fetchWithdrawRequests', { page: this.withdrawRequestPage })

                // this.$router.go(0)
              }, 1000);
            })
            .catch(err => {
              this.$Loading.error()

              this.$Message.error(`Error: ${err?.response?.data?.message ?? err.message}`);

              this.loadingStatus = false;
            })
        } else {
          this.$Message.error('Some fields are missing!');
        }
      })

      // console.log(this.withdrawFormValidate)

    },
    handleResetWithdrawRequest(name) {
      this.$refs[name].resetFields();
    },
    searchForGuarantor(query) {
      if (query !== '') {
        this.guarantorSearchLoading = true;

        setTimeout(() => {
          this.$store
            .dispatch('fetchLoanRequestGuarantor', query)
            .then((response) => {
              this.guarantorSearchLoading = false;

              this.guarantorListOptions = response?.data?.data?.map((item, idx) => ({
                label: `${item?.members?.biodata?.name ?? "N/A"}, ${item?.members?.biodata?.contact ?? "N/A"}`,
                value: item?.members?.id ?? idx,
                ...item
              })) ?? [];
            })
            .catch(() => {
              this.guarantorListOptions = [];
            })

        }, 500);

      } else {
        this.guarantorListOptions = [];
      }
    },
    addSecurity() {
      this.securities.push({ file: null, value: 0 })
    },
    removeSecurity(index) {
      this.securities.splice(index, 1);
    },
    handleUpload(file) {
      // console.log("we here")
      this.loanFormValidate.securityFile = file;
      return false;
    },
    uploadSecurityFile($event, index) {

      this.securities = this.securities.map((security, idx) => {
        if (index === idx) {
          // const currentRef = this.$refs[`security_${index}`]

          return ({ ...security, securityFile: $event.target.files[0] });
        } else {
          return security;
        }
      });
    },
    selectLoanRequest(loanRequest) {
      // console.log(loanRequest)
      this.selectedLoanRequest = loanRequest;
      this.openDeleteModal = true;
    },
    deleteSelectedLoanRequest(selectedLoanRequest) {
      this.$store
        .dispatch('deleteLoanRequest', selectedLoanRequest.id)
        .then(() => {
          this.$Message.success('Successfully deleted loan request');

          setTimeout(() => {
            this.$store
              .dispatch('fetchLoanRequests', { page: this.loanRequestPage })

            this.openDeleteModal = false;
          }, 1000);
        })
        .catch((err) => {
          this.$Message.error(`${err}`);
        })
    },
    handleChangeLoanRequestPage(value) {

      this.$store
        .dispatch('fetchLoanRequests', { page: value })
    },
    handleChangeWithdrawRequestPage(value) {

      this.$store
        .dispatch('fetchWithdrawRequests', { page: value })
    },
  },
  computed: {
    ...mapGetters([
      'getWithdrawRequests',
      'allAccounts',
      'userIn',
      'getBusinessLoanTypes',

    ])
  },
  created() {
    this.$watch(
      () => this.$route.query,
      (newURLSearchParams /*, oldURLSearchParams */) => {
        // react to route changes...
        this.queryParams = newURLSearchParams;

        // console.log(newURLSearchParams, oldURLSearchParams)

        this.fetchAccounts().then(() => {

          this.$store
            .dispatch('fetchWithdrawRequests', { page: this.withdrawRequestPage })

          this.fetchBusinessLoanTypes()

        })
          .catch(err => {
            if (err.code == 'ERR_NETWORK') {
              // this.error(err.code, err.message)
              this.$Message.error(`${err.message}`);
            } else {
              // this.error(err.code, '')
              this.$Message.error(`${err.code}`);
            }
          })
      }
    )

  },
  mounted() {
    // console.log(this.$route.query)
    this.queryParams = this.$route.query

    this.fetchAccounts()
      .then(() => {

        this.$store
          .dispatch('fetchWithdrawRequests', { page: this.withdrawRequestPage })

        this.fetchBusinessLoanTypes()

        this.withdrawFormValidate = this.allAccounts.length === 1 ? { ...this.withdrawFormValidate, account: `${this?.allAccounts[0]?.id}` } : this.withdrawFormValidate;

      })
      .catch(err => {
        if (err.code == 'ERR_NETWORK') {
          // this.error(err.code, err.message)
          this.$Message.error(`${err.message}`);
        } else {
          // this.error(err.code, '')
          this.$Message.error(`${err.code}`);
        }
      })


  }
}
</script>

<style scoped></style>
