<template>
  <div class="container py-3">
    <!-- <h4>Loan Details</h4> -->
    <div v-if="loading">
      <loading-view />
    </div>

    <div v-else>
      <div v-if="Object.keys(loanDetails).length < 1">
        No Data Available
      </div>

      <div v-else>
        <Card class="w-100 mt-3">
          <!-- larger devices -->
          <div class="d-none d-lg-block">
            <PageHeader title="Loan Details" back @on-back="this?.$router.go(-1)">
              <!-- <template #action> -->
              <!--   Loan Details -->
              <!-- </template> -->
            </PageHeader>
          </div>

          <!-- small devices -->
          <div class="d-block d-lg-none">
            <PageHeader title="Loan Details" back @on-back="this?.$router.go(-1)">
              <!-- <template #action> -->
              <!--   Loan Details -->
              <!-- </template> -->
            </PageHeader>
          </div>


          <div class="m-5"></div>
          <!-- v-if="key !== 'id' && !isObject(value) -->
          <Row v-for="(value, key, index) in loanDetails" :key="index">
            <Col span=" 12">
            <Space direction="vertical">
              {{ formatKey(key) }}
            </Space>
            </Col>

            <Col span="12">
            <Space direction="vertical" type="flex">
              {{ formatValue(value) }}
            </Space>
            </Col>
          </Row>



        </Card>

        <!-- {{ -->
        <!--     loanDetails -->
        <!-- }} -->
        <!-- {{ -->
        <!--     this.$store.state.user -->
        <!-- }} -->


      </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import LoadingView from "../components/Loading.vue";

export default {
  name: "LoanDetailsView",
  data() {
    return {
      queryParams: {},
      loading: false,
      loanDetails: {},
    }
  },
  methods: {
    moment: function () {
      return moment();
    },
    formatKey: function (key) {
      return key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    },
    formatValue: function (value) {
      return typeof value === 'boolean' ? value ? 'Yes' : 'No' : value;
    },
    isObject: function (value) {
      return typeof value === 'object' && value !== null;
    },

  },
  computed: {
    ...mapGetters([
    ])
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (newURLSearchParams /*, oldURLSearchParams */) => {
        // react to route changes...
        this.queryParams = newURLSearchParams;

        // console.log(newURLSearchParams, oldURLSearchParams)

        this.loading = true;

        this.$store
          .dispatch('fetchLoanDetails', { loan_id: this.$route.params.id })
          .then((response) => {
            this.loading = false;

            this.loanDetails = response.data.data
          })
          .catch(err => {
            this.loading = false;

            this.$Message.error(`${err}`);
          })


      }
    )

  },
  mounted() {
    // console.log(this.$route)
    this.loading = true;

    this.$store
      .dispatch('fetchLoanDetails', { loan_id: this.$route.params.id })
      .then((response) => {
        this.loading = false;

        this.loanDetails = response.data.data
      })
      .catch(err => {
        this.loading = false;

        this.$Message.error(`${err}`);
      })

  },
  components: {
    LoadingView,
  }
}
</script>

<style scoped></style>
